// eslint-disable-next-line import/extensions
import 'groupby-polyfill/lib/polyfill.js'
import { useEffect } from 'react'
import { useFetchers, useSearchParams } from 'react-router-dom'
import { subYears } from 'date-fns'
import { Divider, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { ALL_GARMENTS, formatDate, formatDateAPI } from '@trinity/utils'
import { ClientAvatar, IMAGE_CARD_FETCHER_KEY, ImageCardList } from '../../components'
import { useGlobalState } from '../../hooks'

interface CloudClosetProps {
  client: TrinityAPI.ClientExtendedType
  garmentTypes: TrinityAPI.GarmentTypeType[]
  garments: Record<string, TrinityAPI.GarmentRenderType[]>
  children?: React.ReactNode
}

export function CloudCloset({ client, garmentTypes, garments, children = null }: CloudClosetProps) {
  const { setToastInfo } = useGlobalState()
  const fetchers = useFetchers()
  const availableGarmentTypes = Object.keys(garments).sort()

  useEffect(() => {
    const fetcher = fetchers.find(f => f.key === IMAGE_CARD_FETCHER_KEY)
    if (fetcher?.data) {
      setToastInfo({ show: true, message: fetcher.data.message, severity: 'success' })
    }
  }, [fetchers, setToastInfo])

  return (
    <Stack spacing={6}>
      <ClientAvatar client={client} />
      <Divider />
      {children}
      <CloudClosetFilters garmentTypes={garmentTypes} />
      {availableGarmentTypes.length < 1 ? (
        <Typography variant='h5' align='center'>
          No Garments Found
        </Typography>
      ) : (
        availableGarmentTypes.map(garmentType => {
          const items = (garments[garmentType] ?? []).map(item => ({
            key: item.id,
            image: item.uri ?? `${item.garmentType.fallbackImage}&width=500`,
            dividerText: item.title,
            primaryText: item.trinityFabricNumber,
            secondaryText: `Ordered On: ${formatDate(item.createdAt)}`,
            hoverItems: [item.lining ? ['Lining', item.lining] : [], item.button ? ['Button', item.button] : []],
            item,
          }))
          const firstItem = items[0]?.item
          const garmentTypeDescription = firstItem?.garmentType?.alternateName ?? firstItem?.garmentType?.formalName
          const action = firstItem?.isHidden ? 'restore' : 'delete'

          return (
            <Stack key={garmentType} spacing={4}>
              <Typography variant='h4'>{garmentTypeDescription}</Typography>
              <ImageCardList items={items} actions={[action]}>
                {garment => (
                  <>
                    <input type='hidden' name='isHidden' value={garment.isHidden ? '0' : '1'} />
                    <input type='hidden' name='id' value={garment.id} />
                  </>
                )}
              </ImageCardList>
            </Stack>
          )
        })
      )}
    </Stack>
  )
}

function CloudClosetFilters({ garmentTypes }: Pick<CloudClosetProps, 'garmentTypes'>) {
  const [params, setParams] = useSearchParams()
  const garmentType = params.get('garmentType') ?? 'all'
  const endDate = params.get('endDate') ? new Date(params.get('endDate') as string) : new Date()
  const startDate = params.get('startDate') ? new Date(params.get('startDate') as string) : subYears(endDate, 3)

  return (
    <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={4} justifyContent='space-between'>
      <TextField
        select
        label='Garment Type'
        value={garmentType}
        onChange={e =>
          setParams(params => {
            params.set('garmentType', e.target.value)
            return params
          })
        }
        sx={{ width: { mobile: 1, laptop: 1 / 5 } }}
      >
        <MenuItem value='all'>All</MenuItem>
        {ALL_GARMENTS.map(garmentType => (
          <MenuItem
            key={garmentType.bitmask}
            disabled={!garmentTypes.includes(garmentType.bitmask)}
            value={garmentType.bitmask}
          >
            {garmentType.alternateName ?? garmentType.formalName}
          </MenuItem>
        ))}
      </TextField>
      <Stack direction='row' spacing={2}>
        <DatePicker
          label='From'
          defaultValue={startDate}
          onChange={newDate =>
            setParams(params => {
              if (newDate) {
                params.set('startDate', formatDateAPI(newDate))
              }
              return params
            })
          }
          slotProps={{
            textField: { helperText: 'MM/DD/YYYY', name: 'startDate' },
            openPickerIcon: { sx: { color: 'grey.900' } },
          }}
        />
        <DatePicker
          label='To'
          defaultValue={endDate}
          onChange={newDate =>
            setParams(params => {
              if (newDate) {
                params.set('endDate', formatDateAPI(newDate))
              }
              return params
            })
          }
          slotProps={{
            textField: { helperText: 'MM/DD/YYYY', name: 'endDate' },
            openPickerIcon: { sx: { color: 'grey.900' } },
          }}
        />
      </Stack>
    </Stack>
  )
}
