import { useEffect } from 'react'

export function useRemoveMaxWidth() {
  useEffect(() => {
    const rootContainer = document.getElementById('root-container')

    if (rootContainer) {
      rootContainer.style.maxWidth = 'unset'
    }

    return () => {
      if (rootContainer) {
        rootContainer.style.maxWidth = ''
      }
    }
  }, [])
}
