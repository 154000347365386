import { motion } from 'motion/react'

interface ExpandProps {
  children: React.ReactNode
}

export function Expand({ children }: ExpandProps) {
  return (
    <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.25 }}>
      {children}
    </motion.div>
  )
}
